import React from 'react'
import { Link } from "gatsby";

import { LINKS} from "../../constants/links";

import CommonContext from '../../components/ContextProvider/CommonContext';
import pdfIcon from './images/pdfIcon.svg';
import videoIcon from './images/video-marine.svg';

import './info.scss';


const InfoPageContextWrapper = (props) => (
	<CommonContext.Consumer>
		{(context) => (
			<InfoPage
				context={context}
				{...props}
			/>
		)}
	</CommonContext.Consumer>
)

const InfoPage = (props) => {
	const baseClassName = 'info-page';
	const infoClassName = {
		component: baseClassName,
		list: `${baseClassName}__list`,
		listItem: `${baseClassName}__list-item`,
		listItemLink: `${baseClassName}__list-item-link`,
		icon: `${baseClassName}__icon`,
		iconDoc: `${baseClassName}__icon-doc`,
		iconVideo: `${baseClassName}__icon-video`,
		infoTitle: 'b-location-spoiler__title',
		infoHeading: `${baseClassName}__heading`,
		infoHeadingContainer: `${baseClassName}__heading-container`,
		componentContainer: `${baseClassName}-container`,
	};

	return (
		<div className={`g-container g-container--header ${infoClassName.componentContainer}`}>
			<div className={infoClassName.infoHeadingContainer}>
				<h1 class={`t-title ${infoClassName.infoHeading}`}>
					Info
				</h1>
				<div className="b-top-screen__line" />
			</div>
			<nav className={infoClassName.component}>
				<ul className={infoClassName.list}>
					<li className={infoClassName.listItem}>
						<a
							className={infoClassName.listItemLink}
							href={LINKS.VIMEO_VIDEO}
							target='_blank'
							onClick={()=>window.plausible('Click VIMEO video', {props: {page: window.location.pathname}})}
						>
							<img
								className={`${infoClassName.icon} ${infoClassName.iconVideo}`}
								src={videoIcon}
							/>
							<span className={infoClassName.infoTitle}>
								Video
							</span>
						</a>
					</li>
					<li className={infoClassName.listItem}>
						<a
							className={infoClassName.listItemLink}
							target='_blank'
							href={LINKS.BROCHURE}
							onClick={()=>window.plausible('Click Open Brochure', {props: {page: window.location.pathname}})}
						>
							<img
								className={`${infoClassName.icon} ${infoClassName.iconDoc}`}
								src={pdfIcon}
							/>
							<span className={infoClassName.infoTitle}>
								Brochure
							</span>
						</a>
					</li>
					<li className={infoClassName.listItem}>
						<a
							className={infoClassName.listItemLink}
							target='_blank'
							href={LINKS.SECURITY_PAPER}
							onClick={()=>window.plausible('Click Open Security Paper', {props: {page: window.location.pathname}})}
						>
							<img
								className={`${infoClassName.icon} ${infoClassName.iconDoc}`}
								src={pdfIcon}
							/>
							<span className={infoClassName.infoTitle}>
								Security Paper
							</span>
						</a>
					</li>
					<li className={infoClassName.listItem}>
						<a
							className={infoClassName.listItemLink}
							target='_blank'
							href={LINKS.PRIVACY_POLICY}
							onClick={()=>window.plausible('Click Open Privacy Policy', {props: {page: window.location.pathname}})}
						>
							<img
								className={`${infoClassName.icon} ${infoClassName.iconDoc}`}
								src={pdfIcon}
							/>
							<span className={infoClassName.infoTitle}>
								Privacy Policy
							</span>
						</a>
					</li>
					<li className={infoClassName.listItem}>
						<a
							className={infoClassName.listItemLink}
							target='_blank'
							href={LINKS.TERMS_OF_SERVICE}
							onClick={()=>window.plausible('Click Open ToS', {props: {page: window.location.pathname}})}
						>
							<img
								className={`${infoClassName.icon} ${infoClassName.iconDoc}`}
								src={pdfIcon}
							/>
							<span className={infoClassName.infoTitle}>
								Terms of Service
							</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	);
};

export default InfoPageContextWrapper;
// here was Gleb
